import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { CardMedia, CircularProgress } from '@material-ui/core';
import { genericHelpers } from '../../../shared/helpers/generics';
import { globalConstants } from '../../../shared/constants/global.constants';
import { alertActions } from '../../../shared/actions/alert.actions';
import DeleteIcon from '@material-ui/icons/Delete';
import en from '../../../../assets/language/en.json';
import styles from '../../tutorials/pages/addOrEditTutorial/index.module.scss';

interface OwnProps {
    file: any;
    title: string;
    style: string;
    remove: () => void;
    fileUrlCallback?: (fileUrl: string) => void;
}

type Props = OwnProps;

const ImportFile = (props: PropsWithChildren<Props>): ReactElement => {
    const [fileExtension, setFileExtension] = useState<string>(null);
    const { file, title, style, remove, fileUrlCallback } = props;
    const [fileToDisplay, setFileToDisplay] = useState<ArrayBuffer | string>(null);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        if (genericHelpers.validString(file) || genericHelpers.isNullOrUndefined(file)) {
            if (!genericHelpers.isNullOrUndefined(file)) {
                const extension = file.split('.').pop().toLowerCase();
                setFileExtension(extension);
            }
            setFileToDisplay(file);
            return;
        }

        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onloadstart = (): void => {
            setLoading(true);
        };

        reader.onload = async (event: ProgressEvent<FileReader>): Promise<void> => {
            const extension = file.name.split('.').pop().toLowerCase();

            if (
                globalConstants.IMG_TYPES.indexOf(extension) <= -1 &&
                globalConstants.MEDIA_TYPES.indexOf(extension) <= -1
            ) {
                alertActions.error(en.not_the_required_format);
            } else {
                const url = window.URL.createObjectURL(file);

                setFileToDisplay(url);
                setFileExtension(extension);
                fileUrlCallback && fileUrlCallback(url);
            }
        };

        reader.onloadend = (): void => {
            setLoading(false);
        };
    }, [file]);

    return loading ? (
        <CircularProgress color="inherit" size={20} />
    ) : (
        <div>
            <DeleteIcon className={styles.deleteIcon} onClick={remove} />
            {globalConstants.MEDIA_TYPES.indexOf(fileExtension) <= -1 ? (
                <CardMedia component="img" className={style} title={title} image={fileToDisplay as string} />
            ) : (
                <CardMedia
                    component="video"
                    className={styles.imgCard}
                    title={en.step_video_picture}
                    controls
                    src={fileToDisplay as string}
                />
            )}
        </div>
    );
};

export default ImportFile;
