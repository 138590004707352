import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect } from 'react';
import {
    AppBar,
    CssBaseline,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    Toolbar,
    Typography,
    List,
    ListItem,
    ListItemText,
    Collapse,
    ThemeOptions,
    Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CategoryIcon from '@material-ui/icons/Category';
import MoreIcon from '@material-ui/icons/More';
import OutdoorGrillIcon from '@material-ui/icons/OutdoorGrill';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import StarIcon from '@material-ui/icons/Star';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter, Link } from 'react-router-dom';
import store, { AppState } from '../../../../store';
import { IPublicNavBar } from '../header.interfaces';
import { ingredientsEndpoints } from '../../../../../modules/ingredients/ingredients.endpoints';
import { categoryTagTypesEndpoints } from '../../../../../modules/categoryTagTypes/category.tag.types.endpoints';
import en from '../../../../../../assets/language/en.json';
import { Email, ExpandLess, ExpandMore, FindInPage, Loyalty, ShowChart } from '@material-ui/icons';
import { muiStyles } from '../../../../styles/mui.styles';
import { categoryTagsEndpoints } from '../../../../../modules/categoryTags/category.tags.endpoints';
import styles from './index.module.scss';
import { cookingMethodsEndpoints } from '../../../../../modules/cookingMethods/cooking.methods.endpoints';
import { influencerConstants } from '../../../../../modules/influencers/influencer.constants';
import { productConstants } from '../../../../../modules/upselling/upselling.constants';
import { recipeConstants } from '../../../../../modules/recipe/recipe.constants';
import { tutorialConstants } from '../../../../../modules/tutorials/tutorial.constants';
import { unitConstants } from '../../../../../modules/unitManagement/unit.constants';
import { equipmentConstants } from '../../../../../modules/equipment/equipment.constants';
import FilterListIcon from '@material-ui/icons/FilterList';
import { filterConstants } from '../../../../../modules/filters/filter.constants';
import { selectionConstants } from '../../../../../modules/selection/selection.constants';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import { actionNamesConstants } from '../../../../../modules/actionNames/action.names.constants';
import { ReactComponent as Accessories } from '../../../../../../assets/svg/accessories.svg';
import { ReactComponent as LogoutIcon } from '../../../../../../assets/svg/logout_icon.svg';
import { authActions } from '../../../../actions/auth.actions';
import { reviewConstants } from '../../../../../modules/reviews/reviews.constants';
import { reviewActions } from '../../../../../modules/reviews/reviews.actions';
import { promotionCampaignEndpoints } from '../../../../../modules/campaigns/promotion.campaign.endpoints';
import { userReportConstants } from '../../../../../modules/userReports/user.report.constants';
import WarningIcon from '@material-ui/icons/Warning';
import { quizQuestionEndpoints } from '../../../../../modules/quizQuestions/quiz.questions.endpoints';
import { troubleshootEndpoints } from '../../../../../modules/troubleshoot/troubleshoot.endpoints';
import { CustomIcons } from '../../../../../../assets/icons';
import { globalSearchEndpoints } from '../../../../../modules/globalSearch/global.search.endpoints';
import { analyticsConstants } from '../../../../../modules/analytics/analytics.constants';
import { sendmailEndpoints } from '../../../../../modules/sendmail/sendmail.endpoints';

const PublicNavbar = (props: PropsWithChildren<IPublicNavBar>): ReactElement<FunctionComponent<IPublicNavBar>> => {
    const {
        window,
        classes,
        theme,
        location: { pathname },
        children,
        noUnreadReviews,
    } = props;
    const [mobileOpen, setMobileOpen] = React.useState<boolean | null>(false);
    const [menuListOpen, setMenuListOpen] = React.useState<boolean>(true);
    const [appliedTheme, setAppliedTheme] = React.useState<boolean>(false);
    const icon = !appliedTheme ? <Brightness7Icon /> : <Brightness3Icon />;
    const applyTheme = () =>
        muiStyles.getMuiTheme(
            appliedTheme ? (muiStyles.darkTheme as ThemeOptions) : (muiStyles.lightTheme as ThemeOptions),
        );

    const logout = (): void => {
        store.dispatch(authActions.logout());
    };

    const handleClick = (): void => {
        setMenuListOpen(!menuListOpen);
    };

    const handleDrawerToggle = (): void => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        reviewActions.countUnreadReviews().then((action) => store.dispatch(action));
    }, []);

    const drawer: JSX.Element = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}>
                <ListItem button onClick={handleClick} className={classes.menuItem}>
                    <ListItemText primary={en.recipe_management_label} />
                    {menuListOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={menuListOpen} timeout="auto" unmountOnExit>
                    <List component={'div'} disablePadding>
                        <ListItem
                            component={Link}
                            className={
                                pathname === recipeConstants.RECIPE_ROUTES.RECIPES
                                    ? classes.selectedListItem
                                    : classes.listItem
                            }
                            to={recipeConstants.RECIPE_ROUTES.RECIPES}
                        >
                            <CustomIcons.RecipeIcon alt={`${en.recipes_label} icon`} width="1.5rem" height="1.5rem" />
                            <ListItemText primary={en.recipes_label} className={classes.listItemText} />
                        </ListItem>
                        <ListItem
                            component={Link}
                            className={
                                pathname === ingredientsEndpoints.INGREDIENTS_ROUTES.INGREDIENTS
                                    ? classes.selectedListItem
                                    : classes.listItem
                            }
                            to={ingredientsEndpoints.INGREDIENTS_ROUTES.INGREDIENTS}
                        >
                            <CategoryIcon />
                            <ListItemText primary={en.ingredients_label} className={classes.listItemText} />
                        </ListItem>
                        <ListItem
                            component={Link}
                            className={
                                pathname === actionNamesConstants.ACTION_NAMES_ROUTES.ACTION_NAMES
                                    ? classes.selectedListItem
                                    : classes.listItem
                            }
                            to={actionNamesConstants.ACTION_NAMES_ROUTES.ACTION_NAMES}
                        >
                            <TouchAppIcon />
                            <ListItemText primary={en.action_names_label} className={classes.listItemText} />
                        </ListItem>
                        <ListItem
                            component={Link}
                            className={
                                pathname === categoryTagsEndpoints.CATEGORY_TAGS_ROUTES.CATEGORY_TAGS
                                    ? classes.selectedListItem
                                    : classes.listItem
                            }
                            to={categoryTagsEndpoints.CATEGORY_TAGS_ROUTES.CATEGORY_TAGS}
                        >
                            <LocalOfferIcon />
                            <ListItemText primary={en.category_tags_label} className={classes.listItemText} />
                        </ListItem>
                        <ListItem
                            component={Link}
                            className={
                                pathname === categoryTagTypesEndpoints.CATEGORY_TAG_TYPES_ROUTES.CATEGORY_TAG_TYPES
                                    ? classes.selectedListItem
                                    : classes.listItem
                            }
                            to={categoryTagTypesEndpoints.CATEGORY_TAG_TYPES_ROUTES.CATEGORY_TAG_TYPES}
                        >
                            <MoreIcon />
                            <ListItemText primary={en.category_tag_types} className={classes.listItemText} />
                        </ListItem>
                        <ListItem
                            component={Link}
                            className={
                                pathname === cookingMethodsEndpoints.COOKING_METHOD_ROUTES.COOKING_METHOD
                                    ? classes.selectedListItem
                                    : classes.listItem
                            }
                            to={cookingMethodsEndpoints.COOKING_METHOD_ROUTES.COOKING_METHOD}
                        >
                            <OutdoorGrillIcon />
                            <ListItemText primary={en.cooking_methods} className={classes.listItemText} />
                        </ListItem>
                        <ListItem
                            component={Link}
                            className={
                                pathname === unitConstants.UNIT_ROUTES.UNITS
                                    ? classes.selectedListItem
                                    : classes.listItem
                            }
                            to={unitConstants.UNIT_ROUTES.UNITS}
                        >
                            <SettingsInputComponentIcon />
                            <ListItemText primary={en.units_label} className={classes.listItemText} />
                        </ListItem>
                        <ListItem
                            component={Link}
                            className={
                                pathname === filterConstants.FILTERS_ROUTES.FILTERS
                                    ? classes.selectedListItem
                                    : classes.listItem
                            }
                            to={filterConstants.FILTERS_ROUTES.FILTERS}
                        >
                            <FilterListIcon />
                            <ListItemText primary={en.filters_label} className={classes.listItemText} />
                        </ListItem>
                        <ListItem
                            component={Link}
                            className={
                                pathname === selectionConstants.SELECTION_ROUTES.SELECTIONS
                                    ? classes.selectedListItem
                                    : classes.listItem
                            }
                            to={selectionConstants.SELECTION_ROUTES.SELECTIONS}
                        >
                            <CustomIcons.SelectionIcon
                                alt={`${en.selections_label} icon`}
                                width="1.5rem"
                                height="1.5rem"
                            />
                            <ListItemText primary={en.selections_label} className={classes.listItemText} />
                        </ListItem>
                        <ListItem
                            component={Link}
                            className={
                                pathname === equipmentConstants.EQUIPMENT_ROUTES.EQUIPMENT
                                    ? classes.selectedListItem
                                    : classes.listItem
                            }
                            to={equipmentConstants.EQUIPMENT_ROUTES.EQUIPMENT}
                        >
                            <Accessories />
                            <ListItemText primary={en.equipment_label} className={classes.listItemText} />
                        </ListItem>
                        <ListItem
                            component={Link}
                            className={
                                pathname === reviewConstants.REVIEW_ROUTES.REVIEWS
                                    ? classes.selectedListItem
                                    : classes.listItem
                            }
                            to={reviewConstants.REVIEW_ROUTES.REVIEWS}
                        >
                            <StarIcon />
                            <ListItemText
                                aria-multiline={false}
                                primary={
                                    <div className={styles.reviewItemWrapper}>
                                        {en.reviews_label}
                                        {noUnreadReviews > 0 ? (
                                            <div className={styles.reviewNotificationNumberWrapper}>
                                                <span className={styles.reviewNotificationNumber}>
                                                    {noUnreadReviews}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                }
                                className={classes.listItemText}
                            />
                        </ListItem>
                        <ListItem
                            component={Link}
                            className={
                                pathname === userReportConstants.USER_REPORT_ROUTES.REPORTED_USERS
                                    ? classes.selectedListItem
                                    : classes.listItem
                            }
                            to={userReportConstants.USER_REPORT_ROUTES.REPORTED_USERS}
                        >
                            <WarningIcon />
                            <ListItemText primary={en.reports} className={classes.listItemText} />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem
                    component={Link}
                    className={
                        pathname === tutorialConstants.TUTORIALS_ROUTES.TUTORIALS
                            ? classes.selectedListItem
                            : classes.listItem
                    }
                    to={tutorialConstants.TUTORIALS_ROUTES.TUTORIALS}
                >
                    <CustomIcons.TutorialIcon alt={`${en.tutorials_label} icon`} width="1.5rem" height="1.5rem" />
                    <ListItemText primary={en.tutorials_label} className={classes.listItemText} />
                </ListItem>
                <ListItem
                    component={Link}
                    className={
                        pathname === influencerConstants.INFLUENCER_ROUTES.INFLUENCERS
                            ? classes.selectedListItem
                            : classes.listItem
                    }
                    to={influencerConstants.INFLUENCER_ROUTES.INFLUENCERS}
                >
                    <CustomIcons.AmbassadorIcon alt={`${en.ambassadors_label} icon`} width="1.5rem" height="1.5rem" />
                    <ListItemText primary={en.ambassadors_label} className={classes.listItemText} />
                </ListItem>
                <ListItem
                    component={Link}
                    className={
                        pathname === productConstants.PRODUCT_ROUTES.PRODUCTS
                            ? classes.selectedListItem
                            : classes.listItem
                    }
                    to={productConstants.PRODUCT_ROUTES.PRODUCTS}
                >
                    <CustomIcons.UpsellingIcon alt={`${en.upselling_label} icon`} width="1.5rem" height="1.5rem" />
                    <ListItemText primary={en.upselling_label} className={classes.listItemText} />
                </ListItem>
                <ListItem
                    component={Link}
                    className={
                        pathname === promotionCampaignEndpoints.PROMOTION_CAMPAIGN_ROUTES.PROMOTION_CAMPAIGN
                            ? classes.selectedListItem
                            : classes.listItem
                    }
                    to={promotionCampaignEndpoints.PROMOTION_CAMPAIGN_ROUTES.PROMOTION_CAMPAIGN}
                >
                    <CustomIcons.MarketingCampaignIcon
                        alt={`${en.campaign_label} icon`}
                        width="1.5rem"
                        height="1.5rem"
                    />
                    <ListItemText primary={en.campaign_label} className={classes.listItemText} />
                </ListItem>
                <ListItem
                    component={Link}
                    className={
                        pathname === quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_QUESTIONS ||
                        quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.ADD_QUIZ_QUESTION === pathname ||
                        pathname.includes(quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.EDIT_QUIZ_QUESTION) ||
                        pathname === quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_ROUNDS ||
                        quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.ADD_QUIZ_ROUND === pathname ||
                        pathname.includes(quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.EDIT_QUIZ_ROUND)
                            ? classes.selectedListItem
                            : classes.listItem
                    }
                    to={quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_ROUNDS}
                >
                    <CustomIcons.QuizQuestionIcon width="1.2rem" height="1.2rem" />
                    <ListItemText primary={'Quiz Questions'} className={classes.listItemText} />
                </ListItem>
                <ListItem
                    component={Link}
                    className={
                        pathname === troubleshootEndpoints.ROUTES.TROUBLESHOOTS ||
                        troubleshootEndpoints.ROUTES.ADD_TROUBLESHOOT === pathname ||
                        pathname.includes(troubleshootEndpoints.ROUTES.EDIT_TROUBLESHOOT)
                            ? classes.selectedListItem
                            : classes.listItem
                    }
                    to={troubleshootEndpoints.ROUTES.TROUBLESHOOTS}
                >
                    <CustomIcons.TroubleshootIcon width="1rem" height="1rem" />
                    <ListItemText primary={'Troubleshoot'} className={classes.listItemText} />
                </ListItem>
                <ListItem
                    component={Link}
                    className={
                        pathname === globalSearchEndpoints.ROUTES.GLOBAL_SEARCH_APP_PAGES
                            ? classes.selectedListItem
                            : classes.listItem
                    }
                    to={globalSearchEndpoints.ROUTES.GLOBAL_SEARCH_APP_PAGES}
                >
                    <FindInPage width="1rem" height="1rem" />
                    <ListItemText primary={'Global Search - App Pages'} className={classes.listItemText} />
                </ListItem>
                <ListItem
                    component={Link}
                    className={
                        pathname === analyticsConstants.ANALYTICS_ROUTES.ANALYTICS_OVERVIEW
                            ? classes.selectedListItem
                            : classes.listItem
                    }
                    to={analyticsConstants.ANALYTICS_ROUTES.ANALYTICS_OVERVIEW}
                >
                    <ShowChart width="1rem" height="1rem" />
                    <ListItemText primary={'Analytics'} className={classes.listItemText} />
                </ListItem>
                <ListItem
                    component={Link}
                    className={
                        pathname === sendmailEndpoints.SENDMAIL_ROUTES.SENDMAIL
                            ? classes.selectedListItem
                            : classes.listItem
                    }
                    to={sendmailEndpoints.SENDMAIL_ROUTES.SENDMAIL}
                >
                    <Email width="1rem" height="1rem" />
                    <ListItemText primary={'Bulk email'} className={classes.listItemText} />
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <MuiThemeProvider theme={applyTheme()}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h5" noWrap className={classes.typography}>
                            Otto Wilde CMS
                        </Typography>
                        <div className={classes.grow} />
                        <div className={styles.headerButtonWrapper}>
                            <div className={classes.sectionDesktop}>
                                <Button
                                    className={classes.changeThemeButton}
                                    startIcon={icon}
                                    onClick={() => setAppliedTheme(!appliedTheme)}
                                >
                                    Change Theme
                                </Button>
                            </div>
                            {props.loggedIn ? (
                                <div className={classes.sectionDesktop}>
                                    <Button
                                        className={classes.logoutButton}
                                        startIcon={<LogoutIcon />}
                                        onClick={() => logout()}
                                    >
                                        Logout
                                    </Button>
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="mailbox folders">
                    {props.loggedIn ? (
                        <React.Fragment>
                            <Hidden smUp implementation="css">
                                <Drawer
                                    container={container}
                                    variant="temporary"
                                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                >
                                    {drawer}
                                </Drawer>
                            </Hidden>
                            <Hidden xsDown implementation="css">
                                <Drawer
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                    variant="permanent"
                                    open
                                >
                                    {drawer}
                                </Drawer>
                            </Hidden>
                        </React.Fragment>
                    ) : null}
                </nav>
                <main className={classes.content}>{children}</main>
            </div>
        </MuiThemeProvider>
    );
};

const mapStateToProps = (state: AppState) => ({
    loggedIn: state.authentication.loggedIn,
    noUnreadReviews: state.reviews.noUnreadReviews,
});

export default compose(
    withRouter,
    withStyles(muiStyles.navBarStyles, { withTheme: true }),
    connect(mapStateToProps, null),
)(PublicNavbar);
